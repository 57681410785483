import { createSelector, createSlice } from '@reduxjs/toolkit';
import { setErrorMessage } from '../../../redux/slices/errors';
import defer from '../../../helpers/defer';
import { DialogRegistry } from './DialogRegistry';
import { DialogResult } from './DialogResult';

const initialState = {
    // queue of dialogs to show. array of shape { type, props }
    dialogs: [],
};

const slice = createSlice({
    name: 'dialogRoot',
    initialState,
    reducers: {
        addDialog(state, action) {
            const { type, props } = action.payload;
            state.dialogs.push({ type, props });
        },
        removeDialog(state) {
            state.dialogs.pop();
        },
        removeSpecificDialog(state, action) {
            state.dialogs = state.dialogs.filter((d) => d.type !== action.payload);
        },
    },
});

export const { addDialog, removeDialog, removeSpecificDialog } = slice.actions;

export default { reducer: slice.reducer, initialState };

// Selectors

/**
 * Selector which returns all queued dialogs along with their props and metadata.
 */
export const dialogsSelector = createSelector(
    (state) => state.dialogRoot.dialogs,
    (dialogs) => {
        return dialogs.map(({ type, props }) => {
            const { component, options } = DialogRegistry.get(type);
            return { type, component, options, props };
        });
    },
);

// Thunks (side effects)

export const openDialog =
    ({ type, props = {} } = {}) =>
    (dispatch, getState) => {
        if (!type) throw new Error('Dialog type is required.');

        // create promise that can be resolved from outside of the promise creation
        const promise = defer();

        const onAccept = (...returnArgs) => {
            promise.resolve(new DialogResult({ returnArgs }));
            dispatch(removeDialog());
        };

        const onCancel = (...returnArgs) => {
            promise.resolve(new DialogResult({ wasCanceled: true, returnArgs }));
            dispatch(removeDialog());
        };

        const dialogProps = {
            ...props,
            onAccept,
            onCancel,
            dialogType: type,
        };

        const queueDialog = async () => {
            try {
                window?.closeCurrentPopup && window.closeCurrentPopup();
                await dispatch(addDialog({ type, props: dialogProps }));
            } catch (err) {
                await dispatch(setErrorMessage({ description: err.message, errorObject: err }));
                promise.resolve(new DialogResult({ hasError: true }));
                // if an error occurs, automatically close dialog
                await dispatch(removeDialog());
            }
        };
        queueDialog();

        return promise;
    };
